import React, { useState } from 'react';

import { Container } from './styles';
import LogoBiplan from '../../../images/Logo/logo_equipe.png';
import { FaBars, FaTimes } from 'react-icons/fa';

function HeaderTerms() {
    const [isClick, setIsClick] = useState(false);

    const handleClick = () => {
        setIsClick(!isClick);
    }

  return(
      <Container>
        <nav className="NavbarItems">
            <div className="navbar_logo">
                <img src={LogoBiplan} width="170px" alt="Logo Biplan"/>
            </div>
            <button className="menu_icon" onClick={handleClick}>
                {
                    isClick 
                    ? <FaTimes size="1.30em" color="#89c03e" /> 
                    : <FaBars size="1.30em" color="#89c03e" />
                }
            </button>
            <ul className={isClick ? 'nav_menu active' : 'nav_menu'}>
                <li><a href="/">Home</a></li>
                <li><a href="/">Serviços</a></li>
                <li><a href="/">Segmentos</a></li>
                <li><a href="/">Planos</a></li>
                <li><a href="https://ajuda.biplan.com.br/">Central de Ajuda</a></li>
                <li><a href="/contato">Contato</a></li>
                <div className="nav_links">
                    <li><a href="https://portal.biplan.com.br/">Acessar Portal</a></li>
                    <li><a href="/assinar"><button>Assinar</button></a></li>
                </div>
            </ul>
            <div className="links">
                <a href="https://portal.biplan.com.br/">Acessar Portal</a>
                <a href="/assinar"><button>Assinar</button></a>
            </div>
        </nav>
      </Container>
  );
}

export default HeaderTerms;