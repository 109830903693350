import React from 'react';
import HeaderTerms from '../HeaderTerms/index';
import Footer from '../../Footer/index';

import { Container, Termos } from './styles';

function Terms() {
  return(
      <>
        <HeaderTerms />
        <Container>
            <h1>Equipe Remota - Termos de Uso e Políticas de Privacidade</h1>

            <Termos>
                <div className="text">
                    <h2>Termos e condições de uso da plataforma Equipe Remota</h2>
                    <p>
                        A ferramenta inclui os termos e condições de uso da 
                        plataforma de gestão empresarial Equipe Remota. Antes de acessar e 
                        usar a plataforma, certifique-se de compreender todas as 
                        condições listadas neste instrumento. Em caso de dúvidas, 
                        entre em contato com a equipe Equipe Remota com antecedência através 
                        dos canais de atendimento Equipe Remota (e-mail, telefone, chat e / ou ticket). 
                        Depois de ler, entender e concordar com todos os termos e condições de uso, 
                        você pode cumprir. Se você não concorda com estes termos e condições de uso, 
                        você deve parar imediatamente de usar a plataforma. Você pode usar a plataforma 
                        somente quando aceitar integralmente os termos e condições de uso. O uso da 
                        plataforma significa a aceitação total, irrestrita e sem reservas de todos os 
                        termos e condições especificados neste documento, incluindo qualquer revisão ou 
                        lançamento de uma nova versão no caso de. . Para a revisão ou lançamento da nova 
                        versão, você será avisado com antecedência, caso não concorde com as alterações, 
                        deve parar de usar a plataforma. Depois de rever os termos e condições de uso, 
                        continuar a usar a plataforma significa aceitar as alterações sem reservas.
                    </p>

                    <div className="definicoes">
                        <h2>1. DEFINIÇÕES</h2>
                        <p>
                            <b>1.1.</b> Definições. Os termos em maiúsculas usados ​​
                            abaixo serão usados ​​nesta ferramenta e têm os 
                            seguintes significados:
                        </p>
                        <p>
                            <b>a.</b> “Equipe Remota”: é a GO.ON DESENVOLVIMENTO DE SOFTWARE, CONSULTORIA LTD, 
                            inscrita no CNPJ sob o n.º 23.315.528/0001-55 com sede na Rua 
                            Criastiano Viana 517 Bairro Cerqueira Cesar, CEP 05397-005, 
                            no Estado de São Paulo.
                        </p>
                        <p>
                            <b>b.</b> “Equipe Remota Conta”: É um conjunto de funções de gestão financeira associadas
                            à plataforma. Através destas funções, os utilizadores poderão realizar 
                            operações em moeda nacional integradas na plataforma, como a emissão e 
                            gestão de ordens de pagamento, transferências de fundos e 
                            acompanhamento financeiro, obtendo assim maior segurança , Velocidade 
                            e transparência.
                        </p>
                        <p>
                            <b>c.</b> “Planos”: Diferentes tipos de planos para a plataforma fornecida pelo Equipe Remota. 
                            O plano fornecerá acesso às funções da plataforma dentro do escopo especificado
                            na descrição do plano selecionada no momento do contrato. Dependendo das funções
                            necessárias e da quantidade de informações armazenadas no servidor Equipe Remota,
                            o plano terá valores diferentes. Todos os planos, independentemente do valor
                            e das funções estipuladas no contrato, estão vinculados a estes termos de uso,
                            incluindo quaisquer planos que sejam fornecidos gratuitamente ou com desconto.
                        </p>
                        <p>
                            <b>d.</b> “Plataforma”: é ferramentas de gestão empresarial, incluindo uma aplicação 
                            de software web, responsável pelas responsabilidades, propriedade e operação do Equipe Remota.
                            O objetivo da plataforma é auxiliar os usuários na realização de tarefas comerciais
                            diárias, incluindo, mas não se limitando a: (i) emissão de notas fiscais; 
                            (ii) controle de caixa; (iii) controle de estoque; (iv) manutenção de cadastros de clientes
                            , fornecedores e produtos; (iv) controle de vendas; (v) controle de finanças;
                            e (vi) integração com lojas virtuais, marketplaces e outras aplicações.
                        </p>
                        <p>
                            <b>e.</b> “Política de Privacidade”: É um conjunto de regras para gerenciar a coleta
                            e uso de dados pessoais de usuários e suas contrapartes.
                        </p>
                        <p>
                            <b>f.</b> Termos de Uso: É esta ferramenta que rege os termos e condições gerais
                            que irão controlar o acesso do usuário e o uso da plataforma. 
                            A Equipe Remota pode formular termos de uso específicos e se aplicar 
                            a certos produtos ou recursos (conforme o caso), esses produtos ou recursos
                            serão complementares e terão precedência sobre estes termos de uso.
                        </p>
                        <p>
                            <b>g.</b> “Usuário”: É um cliente Equipe Remota cadastrado na plataforma, que pode ser pessoa física
                            ou jurídica, independentemente de ser remunerado. Os clientes serão identificados
                            ao preencher o formulário para acessar e utilizar a plataforma.
                        </p>
                    </div>

                    <div className="objeto">
                        <h2>2. OBJETO</h2>
                        <p>
                            <b>2.1.</b> Objeto. O objetivo destes termos de uso é regular os 
                            termos e condições gerais que irão controlar o acesso dos usuários
                            e o uso da plataforma.
                        </p>
                    </div>

                    <div className="cadastro_usuarios">
                        <h2>3. CADASTRO DE USUÁRIOS</h2>
                        <p>
                            <b>3.1.</b> Informações cadastrais. Para utilizar as funções disponibilizadas 
                            pela plataforma, o usuário deve fornecer informações específicas sobre 
                            si mesmo e / ou a pessoa física ou jurídica que representa. 
                            Ao cumprir estes termos de uso, o usuário declara e garante que forneceu 
                            e sempre fornecerá à Equipe Remota informações precisas, atualizadas e verdadeiras. 
                            O usuário declara que sabe que o uso de dados falsos, desatualizados, inválidos, 
                            incorretos ou de terceiros pode acarretar na rescisão antecipada dos termos 
                            destes termos de uso, dificultando a utilização da plataforma pelo usuário, 
                            e não afetará os direitos civis do usuário Responsabilidade penal e indenização
                            por perdas e danos sofridos pela Equipe Remota.
                        </p>
                        <p>
                            <b>3.1.1.</b> Atualização de informações cadastrais. O usuário se compromete a manter
                            todas as suas informações cadastrais atualizadas, devendo atender imediatamente
                            a qualquer solicitação de atualização de cadastro, caso contrário seu cadastro
                            será revogado ou cancelado, e o usuário não receberá qualquer indenização ou 
                            reembolso em razão da revogação ou cancelamento.
                        </p>
                        <p>
                            <b>3.1.2.</b> Bloqueio do acesso por inconsistência em informações cadastrais. 
                            O usuário reconhece e aceita que se (a) o Equipe Remota encontrar informações de
                            registro incorretas, não verdadeiras, desatualizadas ou incorretas e / ou (b)
                            o usuário não fornecer as informações e / ou documentos exigidos pelo Equipe Remota,
                            o Equipe Remota pode decidir por conta própria Se deve bloquear o registro do usuário
                            indefinidamente. Devido ao congelamento do registro, os usuários ficarão
                            temporariamente impedidos de acessar e utilizar as funções da plataforma,
                            não sendo a Equipe Remota qualquer responsabilidade.
                        </p>
                        <p>
                            <b>3.2.</b> Ausência de impedimentos para adesão a este Termos de Uso. Ao cumprir estes termos de uso, 
                            o usuário declara que não tem entraves ao cadastro e acesso à plataforma, nenhum contrato
                            ou garantia de qualquer espécie com terceiros, e nenhuma decisão ou restrição imposta
                            por governo ou autoridade judiciária de qualquer forma. O utilizador declara ter 
                            capacidade legal para contratar a plataforma e compromete-se a pagar a mensalidade
                            atribuível à pessoa singular ou colectiva que assinou o contrato em seu nome. 
                            Se o usuário celebrar um contrato em nome de uma pessoa física ou jurídica que
                            não está autorizado a representar, o usuário será pessoalmente responsável pelas
                            obrigações desta ferramenta (incluindo o pagamento por serviços).
                        </p>
                        <p>
                            <b>3.3.</b> Sigilo das credenciais de acesso à Plataforma. Os usuários são responsáveis ​​por
                            manter suas credenciais (nome de usuário, senha, etc.) confidenciais para acesso
                            à plataforma, e são responsáveis ​​por não compartilhar suas informações de acesso
                            com terceiros. Caso um terceiro acesse a plataforma por negligência do usuário
                            em acessar os dados da plataforma, a Equipe Remota isenta-se de qualquer responsabilidade
                            relacionada à privacidade e segurança das informações e arquivos do usuário. 
                            Exceto pelo cancelamento de todas as informações e arquivos armazenados na plataforma,
                            os usuários não estão autorizados a compartilhar, compartilhar ou fornecer credenciais
                            de acesso para uso de terceiros, caso contrário, serão penalizados com cancelamento
                            da conta e rescisão da relação contratual com a Equipe Remota. O usuário é obrigado a 
                            notificar a Equipe Remota de qualquer perda ou transferência de informações de acesso
                            à conta (nome de usuário, senha, etc.), e ressaltar que a Equipe Remota não será responsável
                            pela segurança e privacidade das informações do usuário nas seguintes circunstâncias:
                            negligência ou reconsideração Use ou compartilhe informações de acesso localmente.
                        </p>
                        <p>
                            <b>3.3.1.</b> Responsabilidade por perfis criados pelo Usuário para uso da Plataforma.
                            O usuário pode criar diversos arquivos de configuração na plataforma para que
                            seus colaboradores possam utilizar a plataforma.De acordo com as configurações
                            selecionadas pelo usuário, o arquivo de configuração pode acessar diferentes
                            funções. Ao utilizar os dados pessoais por si criados, o utilizador será o
                            único e único responsável por todas as actividades da plataforma, devendo
                            informar, orientar e fazer com que os seus parceiros cumpram todas as regras
                            previstas nestes termos de utilização, nomeadamente no que diz respeito à
                            confidencialidade dos seguintes conteúdos Acesse as credenciais da plataforma
                            de regras (nome de usuário, senha, etc.).
                        </p>
                        <p>
                            <b>3.4.</b> Comunicação com o Usuário. O utilizador declara que o e-mail, telefone e endereço
                            físico comunicados à Equipe Remota através da plataforma dispõem dos mesmos meios eficazes,
                            eficazes e suficientes para receber comunicações relacionadas com a sua conta, cancelar
                            ou suspender a utilização da plataforma. O usuário também reconhece e autoriza o uso da
                            plataforma de acesso à homepage como meio eficaz, eficaz e suficiente para a divulgação
                            de assuntos relacionados ao plano estipulado em contrato, incluindo eventuais reajustes
                            de preços ou alterações de funções e restrições de armazenamento.
                        </p>
                    </div>

                    <div className="plataforma">
                        <h2>4. USO DA PLATAFORMA</h2>
                        <p>
                            <b>4.1.</b> Uso da Plataforma em conformidade com o Plano contratado. 
                            Ao assinar um dos planos disponibilizados pela Equipe Remota e cumprindo
                            estes termos de utilização, os utilizadores poderão utilizar as 
                            funções da plataforma de acordo com os termos e condições do plano assinado.
                            Estes termos de uso também se aplicam aos usuários que optam por usar o 
                            plano gratuito fornecido pela Equipe Remota. Equipe Remota pode suspender ou cancelar o 
                            plano gratuito a qualquer momento, sem entrar em contato com o usuário 
                            com antecedência.
                        </p>
                        <p>
                            <b>4.1.1.</b> Dúvidas e questões sobre o Plano contratado. A escolha de um plano 
                            disponibilizado pelo Equipe Remota é parte indispensável do processo de contratação 
                            da plataforma, sendo o utilizador associado ao plano escolhido no momento da adesão. 
                            O usuário declara e garante que o espaço de armazenamento e funções da plataforma 
                            estão claramente descritos e informados no portal eletrônico da Equipe Remota, não podendo 
                            o usuário solicitar à Equipe Remota a prestação de funções ou armazenamento que não estejam 
                            previstos no plano contratual. Caso o usuário tenha alguma dúvida sobre o plano anunciado, 
                            deverá contatar o representante da Equipe Remota através dos canais oficiais de atendimento 
                            da Equipe Remota (e-mail, telefone, chat e ticket) antes de confirmar e cumprir o contrato. usar.
                        </p>
                        <p>
                            <b>4.2.</b> Alteração do Plano contratado. Durante o uso da plataforma pelo usuário, 
                            os usuários podem optar por migrar para outro plano fornecido pelo Equipe Remota. 
                            Neste caso, o valor será alterado e cobrado de acordo com cada novo plano seguido, 
                            e será proporcional ao período de utilização.O custo total da nova mensalidade 
                            entrará em vigor a partir do mês seguinte à alteração da opção do plano. Formalizado.
                        </p>
                        <p>
                            <b>4.3.</b> Condutas proibidas na utilização da Plataforma. O usuário se compromete a utilizar
                            a plataforma de boa fé, sem intenção de cometer atos ilícitos ou causar danos a terceiros.
                            Sem prejuízo ou restrição a estes termos de uso e outras obrigações e restrições 
                            estipuladas nas leis pertinentes, o usuário promete não realizar nenhuma prática e 
                            garantir que qualquer pessoa autorizada com o direito de usar a plataforma não realizará
                            nenhuma das seguintes ações:
                        </p>
                        <p>
                            <b>(a)</b> Distribuir, copiar, modificar, vender, alugar ou utilizar economicamente a plataforma,
                            seus dados, informações, código-fonte, marca, direitos autorais relacionados ou qualquer
                            outro conteúdo comunicado pela Equipe Remota de qualquer forma, a menos que seja obtida 
                            autorização prévia por escrito da Equipe Remota;
                        </p>
                        <p>
                            <b>(b)</b> Faça quaisquer modificações, melhorias, adaptações, trabalhos derivados
                            ou traduções para a plataforma;
                        </p>
                        <p>
                            <b>(c)</b> Descriptografar, decodificar, desmontar, fazer engenharia reversa ou tentar inferir
                            qualquer parte do código-fonte relacionado à plataforma, ideias básicas ou algoritmos;
                        </p>
                        <p>
                            <b>(d)</b> Investigar ou testar a vulnerabilidade de qualquer 
                            sistema ou rede relacionada à plataforma;
                        </p>
                        <p>
                            <b>(e)</b> acessar, adulterar ou usar áreas não públicas da Plataforma ou quaisquer
                            áreas compartilhadas para as quais o Usuário não possui autorização de acesso;
                        </p>
                        <p>
                            <b>(f)</b> causar danos, interferir ou perturbar outros Usuários
                            e/ou servidores de hospedagem ou rede;
                        </p>
                        <p>
                            <b>(g)</b> corromper, danificar, limitar ou interferir na função apropriada
                            e normal de softwares, hardwares, ferramentas ou equipamentos
                            relacionados à Plataforma;
                        </p>
                        <p>
                            <b>(h)</b> obter acesso não autorizado a sistemas, informações, senhas, 
                            ou outros dados da Equipe Remota e/ou de quaisquer Usuários ou terceiros;
                        </p>
                        <p>
                            <b>(i)</b> interferir no funcionamento normal da Plataforma, incluindo por meio de
                            envio ou transmissão de qualquer arquivo malicioso que contenha vírus, worms,
                            cavalos de Tróia ou qualquer outra espécie de programação ou comando que possa
                            contaminar, destruir ou interferir no bom funcionamento da Plataforma;
                        </p>
                        <p>
                            <b>(j)</b> violar ou contornar medidas de segurança e/ou autenticação da Equipe Remota
                            para restringir ou prevenir o acesso a ferramentas, contas, sistemas 
                            e redes relacionadas à Plataforma; 
                        </p>
                        <p>
                            <b>(k)</b> utilizar os serviços contratados para fins de infração à 
                            legislação brasileira, para fins de causar danos a terceiros ou 
                            para fins de armazenar documentos que estejam relacionados a 
                            ilícitos cíveis ou penais.
                        </p>
                        <p>
                            <b>4.4.</b> Acesso à Plataforma. A plataforma pode ser utilizada via Internet, 
                            sendo o usuário integralmente responsável pelo seu equipamento de acesso
                            e sua conexão à Internet, incluindo provedores de acesso, modems e outros
                            intermediários relacionados às conexões privadas. do utilizador. A Equipe Remota 
                            não tem nenhuma obrigação com os equipamentos e conexões específicos do usuário. 
                            O usuário assume a obrigação de não acessar a plataforma por meio de conexão 
                            indireta ao servidor Equipe Remota, e não pode utilizar tecnologia de proxy ou rede 
                            privada virtual (VPN) ou qualquer outra tecnologia que impeça a Equipe Remota de 
                            compreender totalmente a quantidade. O IP (Internet Protocol) usado na conexão 
                            ou o endereço IP exibido é diferente do endereço IP do computador fonte de acesso, 
                            e o contrato assinado pode ser cancelado por violação das regras de segurança 
                            do servidor Equipe Remota. O Usuário é responsável por realizar o acesso à Plataforma 
                            apenas em computadores seguros e privados, que estejam livres de vírus e 
                            que sejam confiáveis do ponto de vista de segurança dos dados inseridos, 
                            não havendo responsabilidade da Equipe Remota por qualquer forma de indenização 
                            em razão de acesso em computadores públicos, como, por exemplo, em lan houses.
                        </p>
                    </div>

                    <div className="manutencao">
                        <h2>5. DISPONIBILIDADE E MANUTENÇÃO DA PLATAFORMA</h2>
                        <p>
                            <b>5.1.</b> Tempo de disponibilidade da Plataforma. A Equipe Remota envidará os seus melhores
                            esforços para que a Plataforma opere continuamente, assumindo a obrigação de 
                            manter o sistema disponível no regime de 24x7x365 (24 horas por dia, 7 dias por semana,
                            365 dias por ano) por no mínimo 99% (noventa e nove por cento) do tempo 
                            de operação de cada mês.
                        </p>
                        <p>
                            <b>5.1.1.</b> Interrupções que não afetam o tempo de disponibilidade da Plataforma.
                            Os períodos de funcionamento afetados por manutenções programadas, 
                            manutenções preventivas, falta de energia elétrica, interrupção ou suspensão
                            da prestação dos serviços de telecomunicações, ocorrências de falhas no sistema
                            de transmissão ou roteamento no acesso à internet, atualizações do sistema, 
                            ou casos fortuitos e de força maior, estarão excluídos do tempo de operação e 
                            não configuram qualquer tipo de responsabilidade ou obrigação de indenizar para Equipe Remota. 
                            Em caso de manutenções urgentes, a Equipe Remota envidará seus melhores esforços para comunicar
                            os Usuários a respeito da possível suspensão ou interrupção dos serviços com antecedência 
                            razoável para não prejudicar as atividades do Usuário.
                        </p>
                        <p>
                            <b>5.1.2.</b> Desconto na mensalidade em caso de violação do tempo de disponibilidade da Plataforma. 
                            Exceto nas hipóteses previstas em 5.1.1, caso a Equipe Remota não consiga garantir que a Plataforma 
                            esteja disponível por no mínimo 99% (noventa e nove por cento) do tempo de operação em um 
                            determinado mês, o Usuário fará jus a um desconto em sua mensalidade proporcional ao tempo 
                            em que a Plataforma tiver ficado indisponível acima do limite previsto acima.
                        </p>
                        <p>
                            <b>5.2.</b> Assistência técnica. A Equipe Remota oferecerá aos Usuários assistência técnica relacionada 
                            a falhas e defeitos do funcionamento da Plataforma, mas não será responsável pela operação 
                            individual dos dispositivos do Usuário. A Equipe Remota utilizará os seus melhores esforços para 
                            responder os chamados abertos pelos Usuários a respeito de problemas ou falhas técnicas da 
                            Plataforma no prazo de até 72 (setenta e duas) horas, momento no qual a Equipe Remota informará o 
                            prazo final para resolução do problema ou falha técnica apontado pelo Usuário.
                        </p>
                        <p>
                            <b>5.2.1.</b> Horário de atendimento. O horário de atendimento telefônico disponibilizado pela Equipe Remota é 
                            de segunda a sexta-feira das 8:20 às 17:45. Qualquer chamada realizada fora dos dias e 
                            horários informados não será atendida.
                        </p>
                        <p>
                            <b>5.2.2.</b> Custos com ligações telefônicas. A Equipe Remota não oferecerá ao Usuário assistência técnica 
                            através de número “0800” ou qualquer outra modalidade de ligação gratuita. As linhas de 
                            telefone colocadas à disposição para fins de assistência técnica estarão localizadas no 
                            município de Bento Gonçalves, Rio Grande do Sul, razão pela qual as chamadas realizadas 
                            pelo Usuário poderão ser cobradas por sua operadora, a depender de tarifas próprias e contratadas. 
                            A Equipe Remota não é responsável pelo pagamento ou ressarcimento de valores gastos com ligações locais 
                            ou interurbanas destinadas à central de assistência, sendo os custos com a chamada telefônica 
                            de total e inteira responsabilidade do Usuário.
                        </p>
                        <p>
                            <b>5.3.</b> Suspensão de uso da Plataforma. A Equipe Remota se reserva o direito de suspender o acesso do 
                            Usuário à Plataforma sem notificação prévia caso seja verificado mau uso, causado 
                            por volume muito acima de médias ou operações consideradas inapropriadas para o 
                            bom uso e segurança da Plataforma.
                        </p>
                        <p>
                            <b>5.4.</b> Descontinuidade da Plataforma. A Equipe Remota poderá suspender, interromper ou descontinuar 
                            a Plataforma, a qualquer tempo, a seu critério e conforme lhe convier, 
                            ainda que fora das hipóteses previstas neste Termos de Uso, mediante aviso 
                            prévio com prazo razoável aos Usuários, sem que isso configure nenhum tipo 
                            de responsabilidade para a Equipe Remota.
                        </p>
                    </div>

                    <div className="prop_intelectual">
                        <h2>6. PROPRIEDADE INTELECTUAL</h2>
                        <p>
                            <b>6.1.</b> Ausência de transferência de direitos de propriedade intelectual. 
                            O utilizador reconhece e aceita que os presentes termos de utilização e 
                            utilização da plataforma não lhe conferem quaisquer direitos, incluindo 
                            mas não se limitando aos direitos de autor e / ou propriedade industrial 
                            da plataforma e / ou de qualquer função ou serviço prestado através deste site. 
                            A plataforma e todo e qualquer material e conteúdo de qualquer natureza 
                            relacionado e / ou relacionado à plataforma ou Equipe Remota e seus parceiros 
                            comerciais e subcontratados, incluindo marcas, sinais exclusivos, 
                            logotipos, imagens, sons, fotos, documentos, fornecidos na plataforma 
                            Texto ou qualquer outro elemento. O usuário ainda reconhece e aceita 
                            que está proibido de usar ou permitir que terceiros usem tais materiais, 
                            conteúdo e quaisquer direitos autorais e / ou direitos de propriedade 
                            industrial de propriedade da Equipe Remota ou de qualquer terceiro licenciado 
                            sem a autorização prévia e expressa do respectivo proprietário, Equipe Remota. 
                            Portanto, o usuário declara que sabe que o uso indevido e a reprodução 
                            total ou parcial do conteúdo e ferramentas referenciados são proibidos, 
                            a menos que expressamente autorizado com antecedência pelo respectivo proprietário.
                        </p>
                        <p>
                            <b>6.2.</b> Autorização de uso precário. O usuário entende e aceita que a autorização 
                            de uso da plataforma e suas funções e ferramentas não pode ser entendida como 
                            atribuição ou atribuição de direitos Equipe Remota aos usuários, mas apenas como uma 
                            autorização para o uso instável da plataforma e suas funções e ferramentas.
                        </p>
                        <p>
                            <b>6.3.</b> Ausência de interferência no funcionamento da Plataforma.Os usuários estão 
                            proibidos de usar qualquer equipamento, software e / ou qualquer outro recurso 
                            que possa interferir nas atividades e / ou operações da plataforma. 
                            Qualquer interferência, tentativa de interferência ou qualquer outra 
                            violação ou violação das regras atuais de direitos autorais e / ou direitos 
                            de propriedade industrial e / ou das proibições destes termos de uso pode 
                            resultar na rescisão destes termos de uso e na suspensão do registro. 
                            Sem prejuízo das responsabilidades civis e criminais do usuário e da compensação 
                            final por perdas e danos, o usuário pode indefinidamente ou até mesmo cancelar, 
                            e o valor pago não será reembolsado.
                        </p>
                        <p>
                            <b>6.4.</b> Responsabilidade sobre informações e conteúdos inseridos na Plataforma. 
                            Os utilizadores são responsáveis ​​por todas as informações enviadas ao servidor Equipe Remota
                            por si próprios ou através dos seus parceiros e representantes no acesso e utilização 
                            da plataforma e por qualquer conteúdo inserido na plataforma, assumindo total 
                            e ilimitada responsabilidade por quaisquer infrações legais. Direitos de terceiros, 
                            incluindo direitos de propriedade intelectual.
                        </p>
                    </div>

                    <div className="privacidade">
                        <h2>7. POLÍTICA DE PRIVACIDADE</h2>
                        <p>
                            <b>7.1.</b> Consentimento com o tratamento de dados pessoais. Ao acessar a plataforma e 
                            utilizar os serviços fornecidos pela Equipe Remota, os usuários obedecem claramente 
                            à política de privacidade e concordam e autorizam a Equipe Remota a processar seus 
                            dados pessoais para os fins determinados e especificados na política de privacidade 
                            e de acordo com os termos e condições detalhados nesta ferramenta e política de 
                            privacidade. doença. O consentimento do usuário pode ser retirado gratuitamente 
                            a qualquer momento, neste caso, o usuário deve entrar em contato com a equipe do 
                            Equipe Remota através do canal oficial de atendimento especificado na política de privacidade. 
                            A retirada do consentimento do usuário significará suspender ou impedir o acesso 
                            à plataforma, e a Equipe Remota não arcará com nenhum ônus ou multas. A retirada do 
                            consentimento não terá efeito retroativo, pois foi aprovado o tratamento de 
                            dados pessoais sob a proteção previamente indicada pelo usuário.
                        </p>
                    </div>

                    <div className="responsabilidade">
                        <h2>8. INDENIDADE E LIMITAÇÃO DE RESPONSABILIDADE</h2>
                        <p>
                            <b>8.1.</b> Indenidade. O usuário se compromete a fornecer gratuitamente indenização 
                            e manutenção à Equipe Remota, seus sócios, gerentes, empregados e contratados, 
                            e a indenizar quaisquer despesas, perdas, despesas, danos, reclamações 
                            e / ou reclamações por qualquer motivo ou por elas causados. Usuários, 
                            seus parceiros, administradores e / ou funcionários violaram estes termos de uso. 
                            Qualquer valor devido pela Equipe Remota aos usuários pode ser retido e compensado 
                            com dívidas contraídas em razão de condenações, taxas e custas processuais 
                            e honorários advocatícios.
                        </p>
                        <p>
                            <b>8.2.</b> Limitação de responsabilidade da Equipe Remota. O usuário reconhece e aceita 
                            que Equipe Remota não assume e não assume qualquer responsabilidade:
                        </p>
                        <p>
                            <b>(a)</b> Por qualquer acesso indevido, atividade suspeita na conta do usuário, 
                            ou falha do usuário em manter a confidencialidade da senha e / ou a falta 
                            ou atraso de comunicação com a Equipe Remota devido a qualquer acesso indevido 
                            ou não uso das possíveis consequências e danos. Autorização de terceiros 
                            para usar senha de usuário;
                        </p>
                        <p>
                            <b>(b)</b> Quaisquer ações ou fatos causados ​​por força maior ou circunstâncias 
                            imprevistas, incluindo, mas não se limitando a serviços de terceiros 
                            (como eletricidade, telefone ou Internet), incêndios, tempestades, 
                            leis, regulamentos, greves, epidemias, motins ou levantes, guerras 
                            Ou qualquer outra razão pela qual Equipe Remota é inevitável ou está além do controle razoável;
                        </p>
                        <p>
                            <b>(c)</b> Perdas do usuário sofridas pela exclusão unilateral de quaisquer dados e 
                            informações inseridos na plataforma, cancelamento de seu registro e 
                            salvamento ou fornecimento de informações sobre ele (incluindo informações 
                            relacionadas à sua identidade, localização e registros) ao conectar e / 
                            ou acessar a plataforma , Desde que estes termos de uso, políticas de privacidade 
                            e / ou ordens judiciais ou administrativas sejam cumpridos;
                        </p>
                        <p>
                            <b>(d)</b> Para modificação final, suspensão, interrupção ou encerramento da plataforma;
                        </p>
                        <p>
                            <b>(e)</b> Em relação a qualquer declaração ou garantia feita com o usuário, 
                            expressa ou implícita, ou por meio de negociação com o usuário, 
                            incluindo, mas não se limitando a: <b>(i)</b> uma garantia implícita aplicável 
                            para uma finalidade específica ou uma garantia que não infrinja 
                            os direitos de terceiros; <b>(ii)</b> Qualquer garantia relacionada à 
                            qualidade, precisão, eficácia, pontualidade ou integridade dos serviços 
                            prestados pela plataforma, ou acesso do usuário ou uso dos serviços da 
                            plataforma estão livres de erros, garantias de vírus, falhas, ininterruptos 
                            ou ininterruptos Atenda a todos os requisitos do usuário;
                        </p>
                        <p>
                            <b>(f)</b> Para o pagamento e / ou recebimento do usuário por erros e / ou atrasos feitos 
                            pelo fornecedor, cliente, funcionário, agente ou qualquer outra pessoa, 
                            o usuário é responsável por verificar o valor (incluindo comissão) 
                            indicado pela plataforma, é sua responsabilidade verificar e confirmar 
                            se está correto Valor, e verifique periodicamente os cálculos e lançamentos 
                            informados pelo sistema para verificar se estão corretos. Pagamentos e 
                            recebimentos do usuário sem a verificação adequada não constituem responsabilidade da Equipe Remota;
                        </p>
                        <p>
                            <b>(g)</b> Perda que pode ser causada por perda de lucro, perda de moralidade, 
                            perda de imagem, perda de oportunidades de negócios e violação de expectativas; 
                        </p>
                        <p>
                            <b>(h)</b> Para qualquer comportamento, fato ou evento que não exija que os 
                            usuários aloquem recursos de forma eficaz.
                        </p>
                        <p>
                            <b>8.2.1.</b> Limite máximo de indenização. Se a Equipe Remota for considerada responsável 
                            pelos danos causados ​​pela plataforma, a indenização ao usuário será 
                            limitada ao valor correspondente aos três (três) meses do plano assinado 
                            pelo usuário no momento da violação. A declaração do usuário é consistente 
                            com esta cláusula de limitação de responsabilidade do Equipe Remota. Se os usuários 
                            não cumprirem esta restrição, eles devem parar de usar a plataforma imediatamente.
                        </p>
                    </div>

                    <div className="preco_pagamento">
                        <h2>9. PREÇO E PAGAMENTO</h2>
                        <p>
                            <b>9.1.</b> Mensalidade. Como contrapartida pela utilização da plataforma, 
                            o usuário deverá pagar ao Equipe Remota o plano mensal estipulado em contrato, 
                            que permitirá ao usuário utilizar a plataforma de forma contínua por 30 dias (30 dias).
                        </p>
                        <p>
                            <b>9.2.</b> Vencimento. Ao assinar um dos planos, o usuário selecionará o prazo 
                            de validade de sua mensalidade por meio da plataforma de emissão de notas, 
                            e o Equipe Remota enviará ao usuário por meio da plataforma e / ou do e-mail 
                            cadastrado no banco. Usuários na plataforma. O pagamento mensal garante 
                            que os usuários possam usar a plataforma independentemente de seu uso real. 
                            Caso você desista, cancele ou não utilize a plataforma antes de 30 (trinta) 
                            dias, a Equipe Remota não se obriga a devolver o valor pago pelo usuário. 
                            Exceto pela obrigação de pagamento da mensalidade, o cancelamento do 
                            contrato estipulado no contrato não acarretará em multas ou outros 
                            custos adicionais para o usuário.
                        </p>
                        <p>
                            <b>9.3.</b> Reajuste do valor dos Planos. A Equipe Remota reserva-se o direito de ajustar 
                            unilateralmente o valor do plano contratual, a função de cada plano e o espaço 
                            de armazenamento de informações na plataforma. Os usuários serão notificados 
                            sobre os novos preços e funcionalidades do plano, que entrará em vigor e será 
                            utilizado na mensalidade no próximo mês. Se o usuário discordar do novo valor 
                            do plano de contrato ou de seus novos recursos, ele deve notificar o Equipe Remota 
                            para cancelar antes de recebê-lo no próximo mês. Certos planos de celebrar um 
                            contrato com valor publicitário não garantem aos usuários o direito a um preço 
                            contratual inicial indefinido, que é afetado pelas mudanças de valor e função 
                            da promoção final do Equipe Remota.
                        </p>
                        <p>
                            <b>9.3.1.</b> Reajuste do preço em razão de alteração tributárias. Se um novo tipo de imposto 
                            for criado ou as condições de cálculo e / ou cobrança de um tipo de imposto existente 
                            forem alteradas, afetando o valor do salário atual, o custo causado pelo impacto será 
                            repassado ao usuário e as taxas anteriormente pagas serão aumentadas. Antes disso, 
                            a Equipe Remota avisará com antecedência.
                        </p>
                        <p>
                            <b>9.4.</b> Atraso no pagamento. Em caso de atraso no pagamento, o usuário deverá pagar a multa 
                            especificada na cláusula 11.2. Além disso, o Equipe Remota reserva-se o direito de cancelar ou 
                            suspender o acesso à plataforma do usuário padrão imediatamente após o vencimento, 
                            sem pagamento sem aviso prévio. Qualquer tolerância do Equipe Remota ao usuário padrão não 
                            excluirá ou restringirá o direito do Equipe Remota de suspender e cancelar o acesso do usuário 
                            à plataforma a qualquer momento após o prazo de pagamento.
                        </p>
                    </div>

                    <div className="vigencia_termino">
                        <h2>10. VIGÊNCIA E TÉRMINO</h2>
                        <p>
                            <b>10.1.</b> Prazo de vigência. Estes termos de uso entram em vigor a partir da data em que o 
                            usuário os aceita e são válidos dentro do tempo acordado do plano, até que sejam 
                            rescindidos ou rescindidos por uma das partes.
                        </p>
                        <p>
                            <b>10.2.</b> Renovação automática do Plano. O plano mensal de renovação do usuário será renovado 
                            automaticamente a cada 30 (trinta) dias utilizando a mesma forma de pagamento do mês anterior. 
                            Caso o usuário não tenha interesse em renovar o contrato, o usuário deve cancelar o plano 
                            estipulado no contrato e notificar o Equipe Remota antes do final do período de 30 (trinta) dias. 
                            Neste caso, a renovação automática não será realizada, ficando o Equipe Remota obrigado a Nenhuma 
                            coleta de qualquer tipo é realizada durante um período. O cancelamento antecipado do plano 
                            não forçará a Equipe Remota a reembolsar a mensalidade paga anteriormente.
                        </p>
                        <p>
                            <b>10.3.</b> Rescisão imotivada. Estes termos de uso podem ser rescindidos por qualquer uma das 
                            partes sem qualquer motivo a qualquer momento, sem qualquer ônus ou punição, 
                            basta enviar um aviso para a outra parte, e o usuário deverá pagar todas as taxas 
                            que eventualmente expirarem mas ainda não foram pagas. Visite a plataforma. 
                            O usuário não é obrigado a devolver qualquer dinheiro pago antecipadamente pelo 
                            usuário em caso de cancelamento antecipado.
                        </p>
                        <p>
                            <b>10.4.</b> Rescisão motivada. Se não houver violação destes Termos de Uso ou das leis aplicáveis ​​dentro 
                            do período especificado, qualquer uma das partes pode rescindir estes Termos de Uso enviando 
                            uma comunicação simples. Se nenhuma solução for feita dentro do período especificado, 
                            ou se não houver cláusula específica e Caso seja possível corrigir a violação, 
                            cinco (5) dias após o recebimento da carta a respeito. Nesse caso, o inocente 
                            tem o direito de reclamar da parte infratora uma indenização por todas as perdas 
                            e danos eventualmente sofridos, observadas as restrições estipuladas neste documento.
                        </p>
                        <p>
                            <b>10.5.</b> Efeitos do encerramento ou cancelamento do Plano. Caso este plano seja rescindido ou cancelado, 
                            por qualquer motivo, deverão ser observadas as seguintes normas: (a) O acesso às funções da 
                            plataforma será imediatamente interrompido e bloqueado; (b) Equipe Remota poderá deletar todos os 
                            arquivos e informações armazenados em seus servidores, independentemente de Se esses arquivos 
                            e informações são recuperados pelos usuários antes de cancelar ou encerrar este programa, 
                            eles devem cumprir as disposições da política de privacidade.
                        </p>
                    </div>

                    <div className="disposicoes">
                        <h2>11. DISPOSIÇÕES GERAIS</h2>
                        <p>
                            <b>11.1.</b> Violação dos Termos de Uso ou da legislação vigente. Se o usuário não cumprir qualquer disposição 
                            destes Termos de Uso ou regulamentos atuais, a Equipe Remota pode interromper, suspender ou cancelar imediatamente, 
                            temporária ou permanentemente este produto sem qualquer aviso, notificação ou qualquer outra forma. 
                            Acesso de conta e usuário à plataforma, sem afetar quaisquer outros direitos garantidos por lei, 
                            termos de uso e / ou política de privacidade da Equipe Remota, a Equipe Remota não poderá reclamar quaisquer 
                            direitos ou cobrar quaisquer taxas a este respeito.
                        </p>
                        <p>
                            <b>11.2.</b> Atraso no pagamento. De acordo com os termos de uso, o atraso no cumprimento de 
                            qualquer obrigação prevista nestes termos de uso resultará em juros mensais de 
                            1% (um por cento), multa de 2% (dois por cento) e positiva. A correção cambial / IBGE ou 
                            outro que venha a substituí-lo (caso tenha sido eliminado) será distribuída proporcionalmente 
                            a partir da data da inadimplência até o efetivo pagamento do valor acima.
                        </p>
                        <p>
                            <b>11.3.</b> Independência das partes. Estes termos de uso não estabelecem qualquer forma de associação, 
                            franquia, parceria, consórcio, joint venture, autorização, emprego, sociedade ou solidariedade, 
                            nem autorizam ambas as partes a praticar quaisquer atos, representar-se, firmar contratos 
                            ou assumir obrigações.
                        </p>
                        <p>
                            <b>11.4.</b> Cessão. A menos que com a autorização expressa e prévia por escrito da Equipe Remota, 
                            o usuário não deve transferir ou transferir a totalidade ou parte dos direitos ou obrigações 
                            relacionados a estes Termos de Uso a terceiros em qualquer capacidade. Por sua vez, 
                            a Equipe Remota está autorizada a transferir ou transferir os direitos e obrigações relacionados 
                            a estes termos de uso a terceiros sem o consentimento prévio do usuário.
                        </p>
                        <p>
                            <b>11.5.</b> Revisões ou atualizações. A Equipe Remota pode alterar estes termos de uso a qualquer 
                            momento e determinar que a nova versão dos termos de uso terá efeito na data de seu 
                            lançamento, e os usuários só podem continuar a usar essas alterações com a plataforma 
                            se concordarem ou discordarem explicitamente. Se o usuário não concordar com a nova versão 
                            referenciada, ele pode cancelar seu registro na plataforma (ou seja, optar por sair). 
                            Nesse caso, o vínculo do contrato deixará de existir, cabendo ao usuário decidir por 
                            conta própria sem qualquer penalidade para o usuário. Após o recebimento da notificação 
                            das alterações dos termos de uso, o uso da plataforma será considerado como a aceitação 
                            do usuário da nova versão dos termos de uso.
                        </p>
                        <p>
                            <b>11.6.</b> Acordo integral. Estes Termos de Uso substituem, substituem e têm precedência sobre 
                            qualquer acordo prévio escrito ou escrito (incluindo a forma eletrônica) ou expressões 
                            orais alcançadas pelas partes com relação aos assuntos considerados neste artigo. 
                            Para evitar dúvidas, estes termos de uso não podem substituir os termos de uso da Equipe Remota Conta, 
                            nem tampouco a política de privacidade. Tais documentos devem ser sempre lidos em conjunto, 
                            e se houver conflito entre seus regulamentos, prevalecerá a norma mais específica.
                        </p>
                        <p>
                            <b>11.7.</b> Sucessores. Estes termos de uso permitem que os usuários e a Equipe Remota 
                            cumpram fielmente e irrevogavelmente e irreversivelmente seus herdeiros em qualquer capacidade.
                        </p>
                        <p>
                            <b>11.8.</b> Necessita de isenções expressas e escritas e princípios de preservação. 
                            A Equipe Remota e o usuário expressamente declaram e concordam: <b>(a)</b> O não exercício 
                            ou atraso no exercício de quaisquer direitos garantidos por este documento ou pela lei 
                            não constitui uma atualização ou renúncia do direito, nem prejudica o possível exercício 
                            desse direito. Mesmo; <b>(b)</b> A renúncia desses direitos é válida apenas quando a forma escrita 
                            (incluindo a forma eletrônica) é formal e a lei proíbe a renúncia de direitos; <b>(c)</b> 
                            A invalidade ou invalidade de qualquer cláusula neste documento não afetará a validade de 
                            outras cláusulas Sexo e potência. Caso a invalidade, revogação ou nulidade de qualquer 
                            cláusula deste documento seja considerada ilegal, a Equipe Remota tomará todas as medidas, 
                            inclusive alterações neste documento, a fim de atingir esse objetivo o máximo possível 
                            de acordo com a legislação vigente. O resultado real.
                        </p>
                        <p>
                            <b>11.9.</b> Títulos. Os cabeçalhos dos termos neste "Termos de Uso" são apenas ilustrativos 
                            e foram inseridos para facilitar a compreensão dos usuários.
                        </p>
                        <p>
                            <b>11.10.</b> Notificações. Todas as notificações e avisos relacionados a estes termos de uso devem 
                            ser feitos por escrito e encaminhados para (a) Se for Equipe Remota, encaminhe para os canais de 
                            atendimento do Equipe Remota (e-mail, telefone, chat e / ou tíquete); (b) Para Para o usuário, seja 
                            o endereço físico, eletrônico e / ou móvel incluído no cadastro na plataforma e / ou o alerta 
                            na área de usuário da plataforma. Se enviado por escrito (inclusive eletronicamente) e por qualquer 
                            método que permita comprovação de recebimento, os avisos e notificações acima serão válidos.
                        </p>
                        <p>
                            <b>11.11.</b> Tributos. Salvo disposição expressa em contrário nesta ferramenta, os impostos direta 
                            ou indiretamente incorridos como resultado da implementação destes termos de uso e o uso de 
                            plataformas (plataformas existentes e / ou possivelmente criadas) e correspondente marcação, 
                            cálculo e / ou período de cálculo, ajuste , Os atrasos de taxas e / ou obrigações fiscais 
                            adicionais constituem a responsabilidade do contribuinte correspondente, conforme definido 
                            na lei atual.
                        </p>
                        <p>
                            <b>11.12.</b> Legislação aplicável. Os usuários declaram conhecer e concordar que, independentemente 
                            de onde utilizem a plataforma, estes termos de uso são regidos pela legislação brasileira.
                        </p>
                        <p>
                            <b>11.13.</b> Foro. Caso você tenha alguma disputa ou desacordo sobre estes termos de uso, por favor, 
                            escolha o Fórum do Município de Bento Gonçalves no Rio Grande do Sul para dirimir 
                            quaisquer dúvidas ou disputas, excluindo quaisquer outros privilégios ou privilégios. 
                            tornar-se.
                        </p>
                    </div>
                </div>
            </Termos>
        </Container>
        <Footer />
      </>
  );
}

export default Terms;