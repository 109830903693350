import React from 'react';

import SEO from "../components/seo";
import GlobalStyles from '../global/styles';
import Termos from '../components/Policies/Terms/index';

function terms() {
  return(
      <>
        <GlobalStyles />
        <Termos />
        <SEO title="Termos de Uso" />
      </>
  );
}

export default terms;
