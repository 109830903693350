import styled from 'styled-components';

export const Container = styled.div`
    background: #F0F0F0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 70px;
`;

export const FooterLogo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0 30px;
    text-align: center;
    width: 100%;
    color: var(--text-color-terciary);
    font-weight: 700;

    h3 {
        padding-top: 50px;
        text-align: center;
    }

    p {
        padding-top: 15px;
    }
    
    hr {
        width: 60%;
        margin-top: 20px;
    }

    @media (max-width: 360px) {
        h3 {
            font-size: 14px;
        }
    }
`;

export const FooterCopyright = styled.div`
    text-align: center;
    color: var(--text-color-quartenary);
    padding: 30px 0 50px 0;

    @media (max-width: 360px) {
        p {
            font-size: 10px;
        }
    }
`;