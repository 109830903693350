import React from 'react';

import { Container, FooterLogo, FooterCopyright } from './styles';
import Logo_Biplan from '../../images/Logo/logo_equipe.png';

function Footer() {
  return(
      <Container>
        <FooterLogo>
          <img src={Logo_Biplan} alt="Logo Biplan" width="250" />
          <h3>Equipe Remota - Gestão inteligente de equipes.</h3>
          <p>GO.ON DESENVOLVIMENTO DE SOFTWARE, CONSULTORIA LTD</p>
          <hr color="#CBE4A9"/>
        </FooterLogo>

        <FooterCopyright>
          <p>Terms & Conditions Privacy Policy</p>
          <p>Copyright © 2020 Delta Tecnologia Engenharia e Sistemas LTDA. All rights reserved. Site credit.</p>
        </FooterCopyright>
      </Container>
  );
}

export default Footer;