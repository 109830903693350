import styled from 'styled-components';

export const Container = styled.div`
    .NavbarItems {
        padding: 10px;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2rem;
        background-color: #F8F8F8;
    }

    .nav_menu {
        list-style: none;
        display: flex;

        .nav_links {
            display: none;
        }
    }

    .navbar_logo {
        padding-left: 20px;
        padding-top: 10px;
    }

    a {
        color: var(--text-color-terciary);
        text-decoration: none;
        font-size: 13px;
        font-weight: bold;
        padding: 0.5rem 1rem;
        transition: 0.4s ease-in;

        &:hover {
            color: var(--color-secondary);
        }
    }

    .links {
        button {
            width: 100px;
            height: 40px;
            border: none;
            border-radius: 5px;
            background-color: var(--color-button-login);
            transition: 0.4s ease-in;
            color: var(--color-white);
            font-size: 12px;
            font-weight: bold;

            &:hover {
                background-color: transparent;
                border: 2px solid var(--color-secondary);
                color: var(--color-secondary);
            }
        }
    }

    .menu_icon {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .links {
            display: none;
        }

        .nav_menu {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 80vh;
            position: absolute;
            top: 80px;
            left: -100%;
            opacity: 1;
            transition: all 0.5s ease;
            overflow: hidden;
            background-color: #F8F8F8;

            .nav_links {
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    width: 100px;
                    height: 40px;
                    border: none;
                    border-radius: 5px;
                    background-color: var(--color-button-login);
                    transition: 0.4s ease-in;
                    color: var(--color-white);
                    font-size: 12px;
                    font-weight: bold;

                    &:hover {
                        background-color: transparent;
                        border: 2px solid var(--color-white);
                        color: var(--color-white);
                    }
                }
            }
        }

        .nav_menu.active {
            background-color: #F8F8F8;
            left: 0;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 1;
        }

        a {
            text-align: center;
            padding: 2rem;
            width: 100%;
            display: table;
        }

        .navbar_logo {
            padding-left: 20px;
        }

        .menu_icon {
            display: block;
            position: absolute;
            background: transparent;
            border: none;
            top: 0;
            right: 0;
            transform: translate(-100%, 40%);
            font-size: 1.8rem;
            cursor: pointer;
        }

        .fa-times {
            color: #fff;
            font-size: 2rem;
        }
    }
`;
