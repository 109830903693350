import styled from 'styled-components';

export const Container = styled.div`
    text-align: center;
    color: var(--text-color-terciary);
    padding-top: 30px;

    @media (max-width: 614px){
        h1 {
            padding: 0 20px 0 20px;
        }
    }
`;

export const Termos = styled.div`
    background-color: #F1FFDF;
    margin-top: 30px;

    .text {
        text-align: left;
        padding: 0 120px 30px 120px;

        h2 {
            padding-top: 30px;
        }

        p {
            text-align: justify;
            margin-top: 15px;
            line-height: 25px;
        }
    }

    @media (max-width: 614px){
        .text {
            text-align: left;
            padding: 0 50px 30px 50px;
        }
    }

    @media (max-width: 414px){
        .text {
            text-align: left;
            padding: 0 30px 30px 30px;
        }
    }
`;